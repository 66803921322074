import {
  generateNoAuthRequest,
  getContextApiUrl,
  genericRequestHandler,
} from './rest';

export interface DashboardContext {
  postcodeBucket: string;
  socketIdentityPoolId: string;
  userPoolId: string;
  clientId: string;
  apiDomain: string;
  DataValidationLambdaURL: string;
  DataScrubbingLambdaURL: string;
  GenerateReportsLambdaUrl: string;
}

export const getContext = async (): Promise<DashboardContext> => {
  const request = generateNoAuthRequest('GET');
  const api = getContextApiUrl();
  const response = await fetch(`${api}/dashboard-context`, request);
  return genericRequestHandler(response);
};
