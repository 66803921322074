import React, { useContext, useMemo, useEffect, useState } from 'react';
import { Button } from '@mui/material';

import { TenantContext } from '../../../../context/TenantContext';

import { DropDown } from '../../../shared/DropDown';
import MDModal from '../../../shared/MDModal';
import { GenericModalProps } from '../../GenericModalProps';
import { enableGnerateReports } from '../../../../services/api/support';

interface EnableGenerateReportsModalProps extends GenericModalProps {
  title: string;
}
const EnableGenerateReportsModal: React.FC<EnableGenerateReportsModalProps> = ({
  open,
  onClose,
  title = 'Delete Election',
}: EnableGenerateReportsModalProps) => {
  const {
    tenants,
    selectedTenant,
    setTenant,
    setElection,
    electionsForTenant,
    selectedElection,
    isLoading,
  } = useContext(TenantContext);

  const tenantNames: string[] = useMemo(() => ['', ...tenants], [tenants]);

  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onSubmit = async (): Promise<void> => {
    setLoading(true);
    try {
      const request = {
        tenant: selectedTenant || '',
        electionDate: selectedElection || '',
      };
      const res = await enableGnerateReports(request);
      console.log(res);
      setSuccess(true);
      setErrorMessage('');

      setTimeout(() => {
        setSuccess(false);
        onClose();
        setElection('');
      }, 3000);
      // eslint-disable-next-line
    } catch (error: any) {
      console.log('Error sending delete election request');
      setSuccess(false);
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const enabled = !!selectedTenant && !!selectedElection;
    setSubmitEnabled(enabled);
  }, [selectedTenant, selectedElection]);

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={title}
      loading={loading || isLoading}
      errorMessage={errorMessage}
      success={success}
      content={
        <>
          <DropDown
            disabled={isLoading}
            options={tenantNames}
            name="Tenants"
            label="Tenants"
            value={selectedTenant}
            onSelect={setTenant}
            fullWidth
          />
          <DropDown
            disabled={isLoading || !selectedTenant}
            options={electionsForTenant}
            name="Election Date"
            label="Date"
            value={selectedElection}
            onSelect={setElection}
            fullWidth
          />
        </>
      }
      actionBar={
        <Button
          disabled={!submitEnabled}
          color="primary"
          variant="contained"
          size="medium"
          fullWidth
          onClick={onSubmit}
        >
          Run Task
        </Button>
      }
    />
  );
};

export default React.memo(EnableGenerateReportsModal);
