import React, { useState, useEffect } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { getContext, DashboardContext } from './services/api/context';
import { WithChildren } from './types/WithChildren';
import Loader from './components/shared/Loader';

let fetchedEnv: DashboardContext;

export const getEnv = async (): Promise<DashboardContext | undefined> => {
  try {
    if (fetchedEnv === undefined) {
      fetchedEnv = await getContext();
    }
    return fetchedEnv;
  } catch (error) {
    console.log(`Error getting Env: ${error}`);
  }
};

export let VALIDATION_LAMBDA_URL = '';
export let DATA_SCRUBBING_URL = '';
export let REPORT_GENERATION_URL = '';

export const EnvLoader: React.FC<WithChildren> = ({
  children,
}: WithChildren) => {
  const [env, setEnv] = useState<DashboardContext | null>(null);

  useEffect(() => {
    getEnv().then((fetchedEnv) => {
      if (fetchedEnv) {
        const config = {
          authenticationFlowType: 'USER_PASSWORD_AUTH',
          Auth: {
            storage: sessionStorage,
            region: 'eu-west-2',
            userPoolId: fetchedEnv.userPoolId,
            userPoolWebClientId: fetchedEnv.clientId,
            identityPoolId: fetchedEnv.socketIdentityPoolId,
          },
          Storage: {
            AWSS3: {
              region: 'eu-west-2',
              bucket: fetchedEnv.postcodeBucket,
            },
          },
        };
        Amplify.configure(config);
        Auth.configure(config);
        console.log(`${JSON.stringify(config)}`);
        setEnv(fetchedEnv);
        VALIDATION_LAMBDA_URL = fetchedEnv.DataValidationLambdaURL;
        DATA_SCRUBBING_URL = fetchedEnv.DataScrubbingLambdaURL;
        REPORT_GENERATION_URL = fetchedEnv.GenerateReportsLambdaUrl;
      }
    });
  }, []);

  return (
    <>
      {env ? (
        children
      ) : (
        <Loader
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
    </>
  );
};
