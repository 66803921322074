import { Button, TextField } from '@mui/material';
import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TenantPollstationContext } from '../../../../context/TenantPollstationContext';
import { reassignBallots } from '../../../../services/api/support';
import { DropDown } from '../../../shared/DropDown';
import MDModal from '../../../shared/MDModal';
import { GenericModalProps } from '../../GenericModalProps';
import { TenantContext } from '../../../../context/TenantContext';
import TenantPollstationPicker from './TenantPollstationPicker';
import { sortAlphaNumeric } from '../../../../utils/utils';
import { Buffer } from 'buffer';
import { startGenerateReports } from '../../../../services/api/dashboard';

interface ReportGeneratorModalProps extends GenericModalProps {
  title: string;
}

const ReportGeneratorModal: React.FC<ReportGeneratorModalProps> = ({
  open,
  onClose,
  title,
}: ReportGeneratorModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedReport, setSelectedReport] = useState<string>('');
  const [success, setSucess] = useState<boolean>(false);

  const {
    fetching,
    selectedTenant,
    selectedPollstation,
    selectedElectionDate,
    tenantPollstationObjects,
    resetForm,
  } = useContext(TenantPollstationContext);

  useMemo(() => {
    if (selectedTenant) {
      for (let i = tenantPollstationObjects.length - 1; i >= 0; i--) {
        if (tenantPollstationObjects[i].usingModernPolling === false) {
          tenantPollstationObjects.splice(i, 1); // Remove the element at index i
        }
      }
    }
  }, [tenantPollstationObjects]);

  const reportTypes: string[] = useMemo(() => {
    if (selectedPollstation) {
      return ['', 'bpa', 'videf'];
    } else {
      return [''];
    }
  }, [selectedPollstation]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const pollstationId =
        selectedPollstation?.pollStationId.split('::')[2] || '';

      const params = {
        tenant: selectedTenant || '',
        stationId: Buffer.from(pollstationId, 'ascii').toString('hex') || '',
        electionDate: selectedElectionDate || '',
        reportType: selectedReport,
      };

      await startGenerateReports(params);

      setSucess(true);
      setSelectedReport('');
    } catch (error: any) {
      setErrorMessage(error.message);
      setLoading(false);
      setTimeout(() => setErrorMessage(''), 5000);
    } finally {
      setLoading(false);
      setTimeout(() => {
        setSucess(false);
      }, 2000);
      resetForm();
      setSelectedReport('');
    }
  };

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={title}
      height={750}
      loading={loading || fetching}
      errorMessage={errorMessage}
      success={success}
      content={
        <>
          <TenantPollstationPicker />
          {selectedPollstation && (
            <DropDown
              options={reportTypes}
              name="Report Type"
              label="Report Type"
              value={selectedReport}
              onSelect={(value) => setSelectedReport(value)}
              fullWidth
            />
          )}
        </>
      }
      actionBar={
        <Button
          disabled={!selectedReport}
          color="primary"
          variant="contained"
          size="medium"
          fullWidth
          onClick={onSubmit}
        >
          Run Task
        </Button>
      }
    />
  );
};

export default ReportGeneratorModal;
