import React, { useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from '@mui/material';
import { Tasks } from '../../../entities/Tasks';
import { TenantPollstationContextProvider } from '../../../context/TenantPollstationContext';

import {
  getProxyVoters,
  getClericalAdditions,
  getElectors,
  getVoterIncompleteStats,
} from '../../../services/api/support';

import SupportTaskItem from '../SupportTaskItem';
import {
  ReassignBallotsModal,
  RevertSkippedModal,
  MarkElectorModal,
  UpdateBallotRefModal,
  UpdateFirstLastElectorModal,
} from './Modals';
import EnableGenerateReportsModal from './Modals/EnabledGenerateReportsModal';
import ReportGeneratorModal from './Modals/ReportGeneratorModal';
import VoterIncompleteProcessModal from './Modals/VoterIncompleteProcessModal';

const ElectionDayTaskList: React.FC = () => {
  const [reassignBallotModalOpen, reassignBallotModalOpenSet] = useState(false);
  const [revertSkippedModalOpen, revertSkippedModalOpenSet] = useState(false);
  const [markClericalAddition, markClericalAdditionSet] = useState(false);
  const [markElector, markElectorSet] = useState(false);
  const [markProxyVoter, markProxyVoterSet] = useState(false);
  const [VoterIncompleteProcessOpen, VoterIncompleteProcessOpenSet] =
    useState(false);
  const [UpdateBallotRefModalOpen, UpdateBallotRefModalOpenSet] =
    useState(false);
  const [UpdateFirstLast, UpdateFirstLastSet] = useState(false);
  const [ReportGeneratorModalOpen, ReportGeneratorModalOpenSet] =
    useState(false);
  const [enableGenerateReportsButton, enableGenerateReportsButtonSet] =
    useState(false);

  return (
    <>
      <TenantPollstationContextProvider>
        {reassignBallotModalOpen && (
          <ReassignBallotsModal
            open={reassignBallotModalOpen}
            onClose={() => reassignBallotModalOpenSet(false)}
          />
        )}
        {revertSkippedModalOpen && (
          <RevertSkippedModal
            open={revertSkippedModalOpen}
            onClose={() => revertSkippedModalOpenSet(false)}
          />
        )}
        {markClericalAddition && (
          <MarkElectorModal
            title="Mark Clerical Addition"
            open={markClericalAddition}
            onClose={() => markClericalAdditionSet(false)}
            electorFetch={getClericalAdditions}
            isClericalAdditions
          />
        )}
        {markProxyVoter && (
          <MarkElectorModal
            title="Mark Proxy Voter"
            open={markProxyVoter}
            onClose={() => markProxyVoterSet(false)}
            electorFetch={getProxyVoters}
            isProxy
          />
        )}
        {markElector && (
          <MarkElectorModal
            title="Mark Elector"
            open={markElector}
            onClose={() => markElectorSet(false)}
            electorFetch={getElectors}
          />
        )}
        {VoterIncompleteProcessOpen && (
          <VoterIncompleteProcessModal
            title="Voter Incomplete"
            open={VoterIncompleteProcessOpen}
            onClose={() => VoterIncompleteProcessOpenSet(false)}
            statsFetch={getVoterIncompleteStats}
          />
        )}
        {UpdateBallotRefModalOpen && (
          <UpdateBallotRefModal
            title="Update Ballot Reference"
            open={UpdateBallotRefModalOpen}
            onClose={() => UpdateBallotRefModalOpenSet(false)}
          />
        )}
        {UpdateFirstLast && (
          <UpdateFirstLastElectorModal
            title="Update the First and Last Elector"
            open={UpdateFirstLast}
            electorFetch={getElectors}
            onClose={() => UpdateFirstLastSet(false)}
          />
        )}
        {ReportGeneratorModalOpen && (
          <ReportGeneratorModal
            open={ReportGeneratorModalOpen}
            onClose={() => ReportGeneratorModalOpenSet(false)}
            title="Generate BPA/VIDEF"
          />
        )}
        {enableGenerateReportsButton && (
          <EnableGenerateReportsModal
            open={enableGenerateReportsButton}
            onClose={() => enableGenerateReportsButtonSet(false)}
            title="Enable Generate Reports Button"
          />
        )}
      </TenantPollstationContextProvider>
      <TableContainer square component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '30%' }}>Task</TableCell>
              <TableCell style={{ width: '60%' }}>Description</TableCell>
              <TableCell style={{ width: '10%' }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <SupportTaskItem
              task={Tasks.RevertSkipped}
              onClick={() => revertSkippedModalOpenSet(true)}
              description="Reverts skipped status on CNL entries to not issued"
            />
            <SupportTaskItem
              task={Tasks.ReassignBallots}
              onClick={() => reassignBallotModalOpenSet(true)}
              description="Update the assigned ballot range for a single pollstation"
            />
            <SupportTaskItem
              task={Tasks.MarkClericalAddition}
              onClick={() => markClericalAdditionSet(true)}
              description="Mark the register for a Clerical Addition"
            />
            <SupportTaskItem
              task={Tasks.MarkProxy}
              onClick={() => markProxyVoterSet(true)}
              description="Mark the register for a Proxy Voter"
            />
            <SupportTaskItem
              task={Tasks.MarkElector}
              onClick={() => markElectorSet(true)}
              description={
                'Mark the register for ANY Voter\n*WARNING* Use with caution and approval of line manager.'
              }
            />
            <SupportTaskItem
              task={Tasks.VoterIncompleteProcess}
              onClick={() => VoterIncompleteProcessOpenSet(true)}
              description="Find incomplete transactions on a polling station level"
            />
            <SupportTaskItem
              task={Tasks.UpdateBallotRef}
              onClick={() => UpdateBallotRefModalOpenSet(true)}
              description="Update the ballot reference of an election"
            />
            <SupportTaskItem
              task={Tasks.UpdateFirstLastElector}
              onClick={() => UpdateFirstLastSet(true)}
              description="Update the first and last elector for an election"
            />
            <SupportTaskItem
              task={Tasks.ReportGenerator}
              onClick={() => ReportGeneratorModalOpenSet(true)}
              description="Generate a BPA or VIDEF report for a polling station"
            />
            <SupportTaskItem
              task={Tasks.EnableGenerateReportsButton}
              onClick={() => enableGenerateReportsButtonSet(true)}
              description="Enable the Generate Report Button so that customers can request the generation of reports"
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ElectionDayTaskList;
